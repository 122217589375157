// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home'; // Your Home Page component
import Categories from './Pages/Category/Category'; // Your Categories Page component
import Explore from './Pages/Explore/Explore'; // Your Explore Page component
import Profile from './Pages/Profile/Profile';
import Cart from './Pages/Cart/Cart'
import Collection from './Pages/Collection/Collection';
import Checkout from './Pages/CheckOut/Address/SaveAddress';
import Payment from './Pages/CheckOut/Payment/Payment';
import SingleProduct from './Pages/SingleProduct/SingleProduct' 
// import BottomNav from './components/BottomNav';


const App = () => {
  
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/explore" element={<Explore/>} />
          <Route path="/profile" element={<Profile />} />
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/collection' element={<Collection />} />
          <Route path="/singleProduct/:id" element={<SingleProduct />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='/payment' element={<Payment/>}/>
        </Routes>
        {/* <BottomNav /> */}
      </div>
    </Router>
  );
};

export default App;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styles from './CardContainer.module.css';

const CardContainer = ({ heading, cardsData, exploreMoreLink }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <h3 className={styles.heading}>{heading}</h3>
        <a href={exploreMoreLink} className={styles.exploreMoreLink}>
          Explore More
        </a>
      </div>
      <div className={styles.cardScroll}>
        {cardsData.map((card) => (
          <Link 
            key={card.id} 
            to={`/singleProduct/${card.id}`}  // Dynamic route with card id
            className={styles.cardLink}
          >
            <div className={styles.cardItem}>
              <div className={styles.overlayText}>{card.overlayText}</div>
              <img src={card.image} alt={card.title} className={styles.cardImage} />
              <div className={styles.cardContent}>
                <div className={styles.contentLeft}>
                  <div className={styles.brandName}>
                    <h3>{card.title}</h3>
                  </div>
                  <div className={styles.subtitle}>
                    <p>{card.subtitle}</p>
                  </div>
                </div>
              </div>
              <div className={styles.price}>
                <span className={styles.currentPrice}>{card.currentPrice}</span>
                <span className={styles.originalPrice}>{card.originalPrice}</span>
                <span className={styles.discount}>{card.discount}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CardContainer;

import React from 'react';
import styles from './ShopByCategory.module.css';
import img1 from '../../assets/oversizedT-shirts-category-img.webp';
import img2 from '../../assets/classicFitT-shirts-category-img.webp';

const cards = [
  { image: img1 },
  { image: img2 },
  { image: img1 },
  { image: img2 },
  { image: img1 },
  { image: img2 },
  { image: img1 },
  { image: img2 },
  { image: img1 },
  { image: img2 },
  { image: img1 },
  { image: img2 },
];

const ShopByCategory = () => {
  return (
    <div className={styles.cardSection}>
      <div className={styles.heading}>
        <h3><span>Shop by category</span> - MEN</h3>
      </div>
      <div className={styles.cardScrollContainer}>
        <div className={styles.cardScroll}>
          {cards.map((card, index) => (
            <div key={index} className={styles.cardItem}>
              <img src={card.image} alt={`Card ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShopByCategory;

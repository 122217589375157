import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import styles from "./CardScroll.module.css";

const CardScroll = ({ cardsData, totalCards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % totalCards;
        scrollRef.current.scrollTo({
          left: newIndex * (scrollRef.current.offsetWidth - 20),
          behavior: "smooth",
        });
        return newIndex;
      });
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [totalCards]);

  const handleScroll = () => {
    const scrollPosition = scrollRef.current.scrollLeft;
    const cardWidth = scrollRef.current.offsetWidth / (1 - 20 / 100);
    const newIndex = Math.round(scrollPosition / cardWidth);
    setCurrentIndex(newIndex);
  };

  return (
    <div className={styles.cardSection}>
      <div
        className={styles.cardScroll}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {cardsData.map((card, index) => (
          <Link
            key={index}
            to="/collection" // Static route to Collection.jsx
            className={styles.cardLink}
          >
            <div className={styles.cardItem}>
              <img src={card.image} alt={card.title} />
            </div>
          </Link>
        ))}
      </div>

      {/* Pagination Dots */}
      <div className={styles.pagination}>
        {cardsData.map((_, index) => (
          <div
            key={index}
            className={`${styles.dot} ${currentIndex === index ? styles.active : ""}`}
            onClick={() => {
              scrollRef.current.scrollTo({
                left: index * (scrollRef.current.offsetWidth - 20),
                behavior: "smooth",
              });
              setCurrentIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CardScroll;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Collection.module.css";
import bannerImg from '../../assets/banner-img-collection.webp';
import { FaChevronLeft, FaShoppingCart } from "react-icons/fa";
import Card from '../card/Card';
import BottomBar from './BottomBar/BottomBar';

const Collection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Safely access 'item' from location.state with a default fallback
  const { item = { text: 'Default Collection', id: 0 } } = location.state || {};

  // Dummy collection items data
  const collectionItems = [
    { id: 1, title: "Stylish T-Shirt", price: 799, originalPrice: 999, discount: 20, imageUrl: "https://via.placeholder.com/150" },
    { id: 2, title: "Casual Shirt", price: 999, originalPrice: 1199, discount: 17, imageUrl: "https://via.placeholder.com/150" },
    { id: 3, title: "Summer Dress", price: 1299, originalPrice: 1499, discount: 13, imageUrl: "https://via.placeholder.com/150" },
  ];

  // Check if the collection is empty
  if (!item) {
    return <p>No collection data available</p>;
  }

  const handleItemClick = (product) => {
    navigate(`/singleProduct/${product.id}`, { state: { product } });
  };

  // Define cardsData using collectionItems
  const cardsData = collectionItems.map((product) => ({
    overlayText: `${product.discount}% Off`,
    image: product.imageUrl,
    subtitle: product.title,
    currentPrice: `₹${product.price}`,
    originalPrice: `₹${product.originalPrice}`,
    discount: `${product.discount}%`,
    id: product.id,
  }));

  return (
    <div className={styles.collectionPage}>
      {/* Navbar Section */}
      <div className={styles.navbar}>
        <div className={styles.backButton}>
          <FaChevronLeft onClick={() => window.history.back()} />
        </div>
        <div className={styles.collectionName}>
          <p>{item.text} Collection</p>
          <span>{collectionItems.length} items</span>
        </div>
        <div className={styles.cartButton}>
          <FaShoppingCart onClick={() => navigate('/cart')} /> {/* Navigate to cart page */}
        </div>
      </div>

      {/* Banner Section */}
      <div className={styles.banner}>
        <img src={bannerImg} alt={item.text} className={styles.bannerImage} />
      </div>

      {/* Collection Items Section */}
      <Card
        heading={`${item.text} Collection`}
        cardsData={cardsData}
        exploreMoreLink="/explore-more"
        onClick={handleItemClick}
      />

      <BottomBar />
    </div>
  );
};

export default Collection;

import React from 'react';
import { FaHome, FaThLarge, FaCompass, FaUser } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import styles from './BottomNav.module.css';

const BottomNav = () => {
  const location = useLocation(); // Get the current location

  return (
    <div className={styles.bottomNav}>
      <Link to="/" className={`${styles.navItem} ${location.pathname === '/' ? styles.navItemActive : ''}`}>
        <FaHome />
        <span>Home</span>
      </Link>
      <Link to="/categories" className={`${styles.navItem} ${location.pathname === '/categories' ? styles.navItemActive : ''}`}>
        <FaThLarge />
        <span>Categories</span>
      </Link>
      <Link to="/explore" className={`${styles.navItem} ${location.pathname === '/explore' ? styles.navItemActive : ''}`}>
        <FaCompass />
        <span>Explore</span>
      </Link>
      <Link to="/profile" className={`${styles.navItem} ${location.pathname === '/profile' ? styles.navItemActive : ''}`}>
        <FaUser />
        <span>Profile</span>
      </Link>
    </div>
  );
};

export default BottomNav;

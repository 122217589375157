import React from 'react';
import { FaMale, FaFemale, FaGlasses, FaShoePrints, FaMoneyBillAlt, FaGoogle, FaAirbnb, FaTools, FaTshirt, FaStar, FaPlus } from 'react-icons/fa';
import styles from './CategoryScroll.module.css'; 

const categories = [
  { name: 'Men', icon: <FaMale /> },
  { name: 'Accessories', icon: <FaGlasses /> }, 
  { name: 'Sneakers', icon: <FaShoePrints /> },
  { name: 'Saving Zone', icon: <FaMoneyBillAlt /> },
  { name: 'Heavy Duty', icon: <FaTools /> },
  { name: 'Customization', icon: <FaTshirt /> },
  { name: 'Official Merch', icon: <FaStar /> },
];

const CategoryScroll = () => {
  return (
    <div className={styles.categoryScroll}>
      {categories.map((category, index) => (
        <div key={index} className={styles.categoryItem}>
          <div className={styles.icon}>{category.icon}</div>
          <span>{category.name}</span>
        </div>
      ))}
    </div>
  );
};

export default CategoryScroll;

import React, { useState } from 'react';
import styles from './SaveAddress.module.css';
import { FaChevronLeft } from "react-icons/fa";
import PaymentPage from '../Payment/Payment'; // Import the PaymentPage component

const SaveAddress = () => {
  const [activeButton, setActiveButton] = useState('others'); // Default active button
  const [errors, setErrors] = useState({}); // State to manage errors
  const [addressSaved, setAddressSaved] = useState(false); // State to track if address is saved

  const handleSaveAddress = () => {
    // Basic validation logic
    const formErrors = {};
    const formFields = ['name', 'number', 'pincode', 'city', 'state', 'addressline1', 'area'];

    formFields.forEach(field => {
      if (!document.getElementById(field).value) {
        formErrors[field] = `${field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} required`;
      }
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Stop further execution if there are errors
    }

    // If no errors, proceed with saving address
    console.log('Address saved');
    setAddressSaved(true); // Set state to true to render PaymentPage
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (value.trim() !== '') {
      setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
    }
  };

  // Conditionally render the PaymentPage component after saving address
  if (addressSaved) {
    return <PaymentPage />;
  }

  return (
    <div className={styles.checkoutPage}>
      <div className={styles.navbar}>
        <FaChevronLeft />
        <p>Add New Address</p>
      </div>

      <div className={styles.customerDetails}>
        <form>
          <input 
            type="text" 
            id="name" 
            name="name" 
            placeholder="Full Name" 
            className={errors.name ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.name && <p className={styles.errorMessage}>{errors.name}</p>}

          <input 
            type="number" 
            id="number" 
            name="number" 
            placeholder="Mobile Number" 
            className={errors.number ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.number && <p className={styles.errorMessage}>{errors.number}</p>}

          <input 
            type="text" 
            id="pincode" 
            name="pincode" 
            placeholder="Pincode/Postal/Zipcode" 
            className={errors.pincode ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.pincode && <p className={styles.errorMessage}>{errors.pincode}</p>}

          <input 
            type="text" 
            id="city" 
            name="city" 
            placeholder="City" 
            className={errors.city ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.city && <p className={styles.errorMessage}>{errors.city}</p>}

          <input 
            type="text" 
            id="state" 
            name="state" 
            placeholder="State" 
            className={errors.state ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.state && <p className={styles.errorMessage}>{errors.state}</p>}

          <input 
            type="text" 
            id="addressline1" 
            name="addressline1" 
            placeholder="Flat no/Building, Street name" 
            className={errors.addressline1 ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.addressline1 && <p className={styles.errorMessage}>{errors.addressline1}</p>}

          <input 
            type="text" 
            id="area" 
            name="area" 
            placeholder="Area/Locality" 
            className={errors.area ? styles.errorInput : ''} 
            onChange={handleInputChange}
          />
          {errors.area && <p className={styles.errorMessage}>{errors.area}</p>}

          <input 
            type="text" 
            id="landmark" 
            name="landmark" 
            placeholder="Landmark (Optional)" 
          />
        </form>
      </div>

      <div className={styles.saveAddressAs}>
        <div className={styles.saveAddressHeading}>
          <p>Save Address As</p>
        </div>
        <div className={styles.saveAsButtons}>
          <button 
            className={`${styles.saveAsButton} ${activeButton === 'home' ? styles.activeButton : ''}`}
            onClick={() => setActiveButton('home')}
          >
            Home
          </button>
          <button 
            className={`${styles.saveAsButton} ${activeButton === 'office' ? styles.activeButton : ''}`}
            onClick={() => setActiveButton('office')}
          >
            Office
          </button>
          <button 
            className={`${styles.saveAsButton} ${activeButton === 'others' ? styles.activeButton : ''}`}
            onClick={() => setActiveButton('others')}
          >
            Others
          </button>
        </div>
      </div>

      <button className={styles.saveAddressBtn} onClick={handleSaveAddress}>SAVE ADDRESS</button>
    </div>
  );
}

export default SaveAddress;

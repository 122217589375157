import React, { useState } from 'react';
import styles from './BottomBar.module.css'; // Adjust the import according to your file structure
import { FaSort, FaFilter } from 'react-icons/fa'; // Import icons

const BottomBar = () => {
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);

  const toggleSortDropdown = () => {
    setIsSortDropdownOpen(!isSortDropdownOpen);
    if (isFilterDropdownOpen) setIsFilterDropdownOpen(false); // Close filter dropdown if open
  };

  const toggleFilterDropdown = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen);
    if (isSortDropdownOpen) setIsSortDropdownOpen(false); // Close sort dropdown if open
  };

  return (
    <div className={styles.bottomBar}>
      <div className={styles.sort} onClick={toggleSortDropdown}>
        <div className={styles.blueDot}></div>
        <div className={styles.upsideDownArrows}>
          <FaSort />
        </div>
        <div className={styles.text}>
          <p>Sort</p>
          <span>Popular</span>
        </div>
        <div className={`${styles.dropdownMenu} ${isSortDropdownOpen ? styles.show : ''}`}>
            <div className={styles.heading}>
            <p>Sort by</p>
            <button className={styles.cancleBtn}>X</button>
            </div>
          <ul>
            <li><strong>Popular</strong></li>
            <li>New</li>
            <li>Price: High to Low</li>
            <li>Price: Low to High</li>
          </ul>
        </div>
      </div>
      <div className={styles.filter} onClick={toggleFilterDropdown}>
        <div className={styles.blueDot}></div>
        <div className={styles.filterIcon}>
          <FaFilter />
        </div>
        <div className={styles.text}>
          <p>Filter</p>
          <span>Category</span>
        </div>
        <div className={`${styles.dropdownMenu} ${isFilterDropdownOpen ? styles.show : ''}`}>
          <ul>
            <li>Electronics</li>
            <li>Fashion</li>
            <li>Home & Kitchen</li>
            <li>Sports</li>
            <li>Beauty</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;

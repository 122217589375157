import React from 'react';
import { FaChevronLeft, FaShoppingCart } from 'react-icons/fa'; // Import icons
import { Link } from 'react-router-dom'; // Import Link for navigation
import styles from './Explore.module.css';
import BottomNav from '../../components/BottomNav/BottomNav';
import img1 from '../../assets/Tribe-Logo-square-bg.webp';
import img2 from '../../assets/Refer-Earn-img.webp';
import img3 from '../../assets/BYOU-explore-img.webp';
import img4 from '../../assets/vote-for-design-img.webp';
import img5 from '../../assets/shop-now-explore-img.webp'

const Explore = () => {
  // Define Card Component Inline
  const Card = ({ imgSrc, text }) => {
    return (
      <div className={styles.card}>
        <img src={imgSrc} alt={text} />
        <p>{text}</p>
      </div>
    );
  };

  // Define card data
  const cardData = [
    { imgSrc: img5, text: 'Shop Now' },
    { imgSrc: img5, text: 'Saving Zone' },
    { imgSrc: img5, text: 'Exolore Now' },
    { imgSrc: img5, text: 'UniClothes x Google' },
    { imgSrc: img5, text: 'UniClothes x Celebs' },
    { imgSrc: img5, text: '' },
    { imgSrc: img5, text: 'Heavy Duty' },
    { imgSrc: img5, text: 'Card 8' },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <Link to="/" className={styles.navItem}>
          <FaChevronLeft className={styles.icon} />
          <span>Explore</span>
        </Link>
        <FaShoppingCart className={styles.cartIcon} />
      </div>
      <div className={styles.content}>
        <p className={styles.heading}>Engage</p>
        <div className={styles.boxContainer}>
          <div className={styles.box}>
            <img src={img1} alt="" />
            <div>
              <p>Tribe Membership</p>
            </div>
            <span className={styles.joinNow}>JOIN NOW</span> {/* Added span for "Join Now" */}
          </div>
          <div className={styles.box}>
            <img src={img2} alt="" />
            <div>
              <p>Refer and Earn</p>
            </div>
          </div>
          <div className={styles.box}>
            <img src={img3} alt="" />
            <div>
              <p>Customise your own T-Shirt</p>
            </div>
          </div>
          <div className={styles.box}>
            <img src={img4} alt="" />
            <div>
              <p>Vote For Designs</p>
            </div>
          </div>
        </div>
        <div className={styles.scrollableContainer}>
          <p className={styles.heading}>Popular Curations</p>
          <div className={styles.cardsWrapper}>
            <div className={styles.cardsRow}>
              {cardData.slice(0, 4).map((card, index) => (
                <Card key={index} imgSrc={card.imgSrc} text={card.text} />
              ))}
            </div>
            <div className={styles.cardsRow}>
              {cardData.slice(4).map((card, index) => (
                <Card key={index + 4} imgSrc={card.imgSrc} text={card.text} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Explore;

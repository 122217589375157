import React, { useState } from 'react';
import styles from './Category.module.css';
import { useNavigate } from 'react-router-dom';
import BottomNav from '../../components/BottomNav/BottomNav'
import topWearImg1 from '../../assets/top-wear-category-img.webp'

const Category = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  // State to track which sidebar item is active
  const [activeItem, setActiveItem] = useState('Topwear');
  

  // Mock data for the cards
  const cardData = {
    Topwear: [
      { img: topWearImg1, text: 'T-Shirts' },
      { img: topWearImg1, text: 'Printed T-Shirts' },
      { img: topWearImg1, text: 'Topwear 3' },
      { img: topWearImg1, text: 'Topwear 4' },
      { img: topWearImg1, text: 'T-Shirts' },
      { img: topWearImg1, text: 'Printed T-Shirts' },
      { img: topWearImg1, text: 'Topwear 3' },
      { img: topWearImg1, text: 'Topwear 4' },
      { img: topWearImg1, text: 'T-Shirts' },
      { img: topWearImg1, text: 'Printed T-Shirts' },
      { img: topWearImg1, text: 'Topwear 3' },
      { img: topWearImg1, text: 'Topwear 4' },
      { img: topWearImg1, text: 'T-Shirts' },
      { img: topWearImg1, text: 'Printed T-Shirts' },
      { img: topWearImg1, text: 'Topwear 3' },
      { img: topWearImg1, text: 'Topwear 4' },
      { img: topWearImg1, text: 'T-Shirts' },
      { img: topWearImg1, text: 'Printed T-Shirts' },
      { img: topWearImg1, text: 'Topwear 3' },
      { img: topWearImg1, text: 'Topwear 4' },
    ],
    Bottomwear: [
      { img: topWearImg1, text: 'Bottomwear 1' },
      { img: topWearImg1, text: 'Bottomwear 2' },
    ],
    Accessories: [
      { img: topWearImg1, text: 'Accessories 1' },
      { img: topWearImg1, text: 'Accessories 2' },
    ],
    'Top Sellers': [
      { img: topWearImg1, text: 'Top Seller 1' },
      { img: topWearImg1, text: 'Top Seller 2' },
    ],
    'Shop by Fandom': [
      { img: topWearImg1, text: 'Fandom 1' },
      { img: topWearImg1, text: 'Fandom 2' },
    ],
    Winterwear: [
      { img: topWearImg1, text: 'Winterwear 1' },
      { img: topWearImg1, text: 'Winterwear 2' },
    ],
    'Plus Size': [
      { img: topWearImg1, text: 'Plus Size 1' },
      { img: topWearImg1, text: 'Plus Size 2' },
    ],
    'Innerwear & Loungewear': [
      { img: topWearImg1, text: 'Innerwear 1' },
      { img: topWearImg1, text: 'Loungewear 1' },
    ],
    Specials: [
      { img: topWearImg1, text: 'Special 1' },
      { img: topWearImg1, text: 'Special 2' },
    ],
  };


  // Function to handle card click
  const handleCardClick = (item) => {
    // Navigate to Collection page and pass the item data
    navigate('/collection', { state: { item } });
  };

  const renderCards = () => {
    return cardData[activeItem]?.map((card, index) => (
      <div 
        key={index} 
        className={styles.card}
        onClick={() => handleCardClick(card)} // Add onClick to navigate
      >
        <img src={card.img} alt={card.text} className={styles.cardImage} />
        <p>{card.text}</p>
      </div>
    ));
  };


  return (
    <div className={styles.categories}>
      {/* Navbar */}
      <div className={styles.navbar}>
        <a href="/">Men</a>
        <a href="/">Accessories</a>
        <a href="/">Sneakers</a>
      </div>

      <div className={styles.content}>
        {/* Sidebar */}
        <div className={styles.sidemenu}>
          {[
            'Topwear', 'Bottomwear', 'Accessories', 'Top Sellers', 'Shop by Fandom', 
            'Winterwear', 'Plus Size', 'Innerwear & Loungewear', 'Specials'
          ].map((item) => (
            <div
              key={item}
              className={`${styles.item} ${activeItem === item ? styles.active : ''}`}
              onClick={() => setActiveItem(item)}
            >
              <span>{item}</span>
            </div>
          ))}
        </div>

        {/* Cards area (remaining 60% of the screen) */}
        <div className={styles.cardsContainer}>
          <div className={styles.cardGrid}>{renderCards()}</div>
        </div>
      </div>
      <BottomNav/>
    </div>
  );
};

export default Category;

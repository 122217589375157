import React, { useState } from "react";
import styles from "./Cart.module.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import couponImg from '../../assets/coupon-icon.svg';
import couponImg2 from '../../assets/coupon-icon-green.svg'

// Sample data for cart items
const dummyProducts = [
  {
    id: 1,
    name: "T-Shirt",
    dis: "Long shirt tishklflj",
    image: "https://via.placeholder.com/150",
    price: 19.99,
    discount: 15.99, // Added discounted price
    quantity: 1,
    selectedSize: "M", // Initially selected size
  },
  {
    id: 2,
    name: "Coffee Mug",
    dis: "Long shirt tishklflj",
    image: "https://via.placeholder.com/150",
    price: 9.99,
    discount: 7.99, // Added discounted price
    quantity: 2,
    selectedSize: "L", // Initially selected size
  },
];

const Cart = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  // State to store cart items
  const [cartItems, setCartItems] = useState(dummyProducts);

  // Function to handle size change for a specific cart item
  const handleSizeChange = (itemId, newSize) => {
    const updatedItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, selectedSize: newSize } : item
    );
    setCartItems(updatedItems);
  };

  // Function to handle quantity change
  const handleQuantityChange = (itemId, newQuantity) => {
    const updatedItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    );
    setCartItems(updatedItems);
  };

  // Function to handle item removal from the cart
  const handleRemoveItem = (itemId) => {
    const updatedItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedItems);
  };

  // Calculate total price of items in the cart
  const calculateTotalPrice = () => {
    return cartItems
      .reduce((acc, item) => acc + item.discount * item.quantity, 0)
      .toFixed(2);
  };

  // Calculate total original price of items in the cart
  const calculateTotalOriginalPrice = () => {
    return cartItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const handleProceedToCheckout = () => {
    navigate("/CheckOut");
    const calculatedSubtotal = calculateTotalPrice();
  navigate("/payment", { state: { subtotal: calculatedSubtotal } });
  };

  return (
    <div className={styles.cartPage}>
      <div className={styles.cartNavbar}>
        <Link to="/">
          <div className={styles.cartBackIcon}>
            <FaChevronLeft />
          </div>
        </Link>
        <div className={styles.cartHeading}>
          <p>
            Cart ({cartItems.length} {cartItems.length === 1 ? "item" : "items"})
          </p>
        </div>
      </div>

      <div className={styles.gapDiv}></div>

      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className={styles.cartItems}>
          {cartItems.map((item) => (
            <div key={item.id} className={styles.cartItem}>
              <img
                src={item.image}
                alt={item.name}
                className={styles.cartItemImage}
              />
              <div className={styles.cartItemDetails}>
                <h4>{item.name}</h4>
                <p>{item.dis}</p>
              </div>
              <div className={styles.cartControls}>
                {/* Size Select */}
                <div className={styles.cartSize}>
                  <label htmlFor={`size-${item.id}`}>Size:</label>
                  <select
                    id={`size-${item.id}`}
                    className={styles.sizeSelect}
                    value={item.selectedSize}
                    onChange={(e) => handleSizeChange(item.id, e.target.value)}
                  >
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="2XL">2XL</option>
                  </select>
                </div>

                {/* Quantity Input */}
                <div className={styles.cartQuantity}>
                  <label htmlFor={`quantity-${item.id}`}>Qty:</label>
                  <select
                    id={`quantity-${item.id}`}
                    className={styles.quantitySelect}
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                  >
                    {[...Array(10).keys()].map(num => (
                      <option key={num + 1} value={num + 1}>
                        {num + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.priceSection}>
                  <div className={styles.priceRow}>
                    <p className={styles.discountedPrice}>₹{(item.discount * item.quantity).toFixed(2)}</p>
                    <p className={styles.originalPrice}>₹{(item.price * item.quantity).toFixed(2)}</p>
                  </div>
                  <span className={styles.savedAmount}>
                    You Saved: ₹{((item.price - item.discount) * item.quantity).toFixed(2)}
                  </span>
                </div>
              </div>
              <button
                className={styles.removeItemButton}
                onClick={() => handleRemoveItem(item.id)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      )}

<div className={styles.gapDiv}></div>

<div className={styles.offersAndCoupons}>
  <div className={styles.offerHeading}>
    <p>Coupons & Offers</p>
  </div>
  <div className={styles.applyCoupon}>
    <img src={couponImg} alt="Coupon Icon" />
    <div className={styles.couponText}>
      <p>Apply Coupon / Gift Card</p>
      <span>Crazy deals and other amazing offers</span>
    </div>
    <FaChevronRight className={styles.FaChevronRight} />
  </div>
</div>

<div className={styles.offerYouAreMissing}>
  <p>Offer you are missing out on</p>
</div>

<div className={styles.flatDiscount}>
  <div className={styles.flatCoupon}>
    <img src={couponImg2} alt="Coupon Icon" />
    <div className={styles.flatText}>
      <p>FLAT10</p>
      <span>Get Extra 10% instant discount on all orders above Rs.2999. Coupon code - FLAT10</span>
    </div>
    <button className={styles.applyButton}>APPLY</button>
  </div>
</div>
<div className={styles.gapDiv}></div>





<div className={styles.priceSummary}>
  <h2 className={styles.summaryHeading}>Price Summary</h2>
  <div className={styles.summaryItem}>
    <p>Total MRP (Incl. of taxes):</p>
    <p>₹{calculateTotalOriginalPrice()}</p>
  </div>
  <div className={styles.summaryItem}>
    <p>Delivery Fee:</p>
    <p className={styles.delivery}>FREE</p> {/* Replace `deliveryFee` with your actual delivery fee variable */}
  </div>
  <div className={styles.summaryItem}>
    <p>Bag Discount:</p>
    <p>₹{calculateTotalOriginalPrice() - calculateTotalPrice()}</p> {/* Total saved amount */}
  </div>
  <div className={styles.summaryItem}>
    <p className={styles.subtotal}>Subtotal:</p>
    <p className={styles.subtotal}>₹{calculateTotalPrice()}</p> {/* Total amount after discount */}
  </div>
</div>

<div className={styles.youAreSaving}>
  <p>You are saving ₹{calculateTotalOriginalPrice() - calculateTotalPrice()} on this order</p>
</div>

<div className={styles.bottomBar}>
  {cartItems.length > 0 && (
    <>
      <div className={styles.leftSide}>
        <div className={styles.subtotalSection}>
          <p className={styles.subtotalPrice}>₹{calculateTotalPrice()}</p>
          <span>VIEW DETAILS</span>
        </div>
      </div>
      <div className={styles.rightSide}>
        <button onClick={handleProceedToCheckout}  className={styles.checkoutButton}>Proceed to Checkout</button>
      </div>
    </>
  )}
</div>


    </div>
  );
};

export default Cart;

import React, { useState } from 'react';
import styles from './Payment.module.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import walletImg from '../../../assets/wallet-img.webp';
import bankImg from '../../../assets/net-banking-img.webp';
import cashImg from '../../../assets/cash-on-delivery-img.webp';
import cartBagImg from '../../../assets/cart-badge-trust.svg';
import EasyReturnsImg from '../../../assets/frame-easy-trust.svg';
import OriginalImg from '../../../assets/original-icon.webp';
import GooglePayImg from '../../../assets/google-pay-logo.svg';
import upiImg from '../../../assets/upi-sign.svg';
import PhonePeImg from '../../../assets/phone-pay-logo.svg';
import visaImg from '../../../assets/visa-card-logo-9.svg';
import masterCardImg from '../../../assets/master-card.svg';

const PaymentPage = () => {
  const [selectedPayment, setSelectedPayment] = useState("upi");
  const navigate = useNavigate(); 
  const location = useLocation();
  const { subtotal } = location.state || { subtotal: 0 };

  // Dropdown state management
  const [isAddressDropdownOpen, setIsAddressDropdownOpen] = useState(false);
  const [isItemsDropdownOpen, setIsItemsDropdownOpen] = useState(false);
  const [isAddNewCardDropdownOpen, setAddNewCardDropdownOpen] = useState(false);
  const [isUpiDropdownOpen, setUpiDropdownOpen] = useState(false);
  const [isWalletDropdownOpen, setWalletDropdownOpen] = useState(false);
  const [isCashDropdownOpen, setCashDropdownOpen] = useState(false);
  const [isBankDropdownOpen, setBankDropdownOpen] = useState(false);

  // Toggle dropdowns
  const toggleDropdown = (dropdown) => {
    switch (dropdown) {
      case 'address':
        setIsAddressDropdownOpen(!isAddressDropdownOpen);
        if (isItemsDropdownOpen) setIsItemsDropdownOpen(false);
        break;
      case 'items':
        setIsItemsDropdownOpen(!isItemsDropdownOpen);
        if (isAddressDropdownOpen) setIsAddressDropdownOpen(false);
        break;
      case 'addCard':
        setAddNewCardDropdownOpen(!isAddNewCardDropdownOpen);
        break;
      case 'upi':
        setUpiDropdownOpen(!isUpiDropdownOpen);
        break;
      case 'wallet':
        setWalletDropdownOpen(!isWalletDropdownOpen);
        break;
      case 'bank':
        setBankDropdownOpen(!isBankDropdownOpen);
        break;
      case 'cash':
        setCashDropdownOpen(!isCashDropdownOpen);
        break;
      default:
        break;
    }
  };

  const items = [
    { id: 1, name: "Product 1", price: 999, image: "https://via.placeholder.com/80", discountedPrice: 799, deliveryDate: "20 Sep", quantity: 2 },
    { id: 2, name: "Product 2", price: 499, image: "https://via.placeholder.com/80", discountedPrice: 399, deliveryDate: "22 Sep", quantity: 1 },
  ];

  const ItemCard = ({ item }) => {
    const savedAmount = item.price - item.discountedPrice;
    return (
      <div className={styles.itemCard}>
        <div className={styles.imageContainer}>
          <img src={item.image} alt={item.name} className={styles.itemImage} />
          <div className={styles.quantity}>{item.quantity}</div>
        </div>
        <div className={styles.itemDetails}>
          <h4 className={styles.itemName}>{item.name}</h4>
          <p className={styles.itemDesc}>Description of {item.name}</p>
          <p className={styles.deliveryDate}>Delivered by: {item.deliveryDate}</p>
          <div className={styles.prices}>
            <p className={styles.discountedPrice}>₹{item.discountedPrice}</p>
            <p className={styles.actualPrice}>₹{item.price}</p>
            <p className={styles.savedAmount}>You Saved: ₹{savedAmount}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleBackButton = () => {
    navigate('/');
  }

  return (
    <div className={styles.paymentPage}>
      <div className={styles.paymentNavbar} onClick={handleBackButton}>
        <FaChevronLeft />
        <p>Payment: ₹{subtotal}</p>
      </div>

      {/* Address dropdown */}
      <div className={styles.checkAddress} onClick={() => toggleDropdown('address')}>
        <div className={styles.addressType}>
          <span>Home</span>
        </div>
        <div className={styles.addressUser}>
          <p>Arpit Dhangar</p>
        </div>
        <div className={styles.dropDownIcon}>
          {isAddressDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isAddressDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.userNameContainer}>
            <div className={styles.userName}>Arpit Dhangar</div>
            <div className={styles.buttonType}>Home</div>
          </div>
          <div className={styles.addressContainer}>
            <div className={styles.add}>
              <p>1, Dhar, Madhya Pradesh, 454335</p>
            </div>
            <div className={styles.changeButton}>CHANGE</div>
          </div>
        </div>
      )}

<div className={styles.gapDiv}></div>

      {/* Items dropdown */}
      <div className={styles.checkItems} onClick={() => toggleDropdown('items')}>
        <div className={styles.itemHead}>
          <span>ITEMS ({items.length})</span>
        </div>
        <div className={styles.dropDownIcon}>
          {isItemsDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isItemsDropdownOpen && (
        <div className={styles.cardContainer}>
          <div className={styles.itemsContent}>
            {items.map(item => (
              <ItemCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      )}

<div className={styles.gapDiv}></div>






<div className={`${styles.debitAndCreditCard} ${isAddNewCardDropdownOpen ? styles.open : ''}`} onClick={() => toggleDropdown('addCard')}>
  <div className={styles.debitHeading}>
    <p>Debit & Credit Card</p>
  </div>
  <div className={`${styles.addNewCard} ${isAddNewCardDropdownOpen ? styles.open : ''}`}>
    <div className={styles.icon}>
      <FaPlus />
    </div>
    <div className={styles.text}>
      <p>ADD NEW CARD</p>
    </div>
    <div className={styles.dropDownIcon}>
      {isAddNewCardDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
    </div>
  </div>
  {isAddNewCardDropdownOpen && (
    <div className={styles.cardForm}>
      <div className={styles.formGroup}>
        <label htmlFor="cardNumber">Card Number</label>
        <input type="text" id="cardNumber" placeholder="Enter your card number" />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="validThrough">Valid Through</label>
        <input type="text" id="validThrough" placeholder="MM/YY" />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="cvv">CVV</label>
        <input type="text" id="cvv" placeholder="Enter CVV" />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="cardName">Name on Card</label>
        <input type="text" id="cardName" placeholder="Enter cardholder's name" />
      </div>
      <div className={styles.checkboxContainer}>
        <input type="checkbox" id="secureCard" />
        <label htmlFor="secureCard">Secure your card for future transactions</label>
      </div>
      <div className={styles.payButton}>
        <button>Pay</button>
      </div>
    </div>
  )}
</div>






      {/* UPI */}
      <div className={styles.upiSection} onClick={() => toggleDropdown('upi')}>
        <div className={styles.upiHeading}>
          <p>UPI</p>
        </div>
        <div className={styles.addNewUpi}>
          <div className={styles.icon}>
            <FaPlus />
          </div>
          <div className={styles.text}>
            <p>ADD NEW UPI ID</p>
          </div>
          <div className={styles.dropDownIcon}>
            {isUpiDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>

      {/* Other Payment Options */}
      <div className={styles.otherPaymentOptions}>
        <div className={styles.otherMethodHeading}>
          <p>Other Payment Options</p>
        </div>
        <div className={styles.paymentOptions}>
          <div className={styles.option} onClick={() => toggleDropdown('wallet')}>
            <div className={styles.optionIcon}>
              <img src={walletImg} alt="wallet" />
            </div>
            <div className={styles.optionName}>
              <p>Wallet</p>
              <span>Paytm, PhonePe & More</span>
            </div>
            <div className={styles.dropDownIcon}>
              {isWalletDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {isWalletDropdownOpen && (
            <div className={styles.optionDetails}>
              <p>Wallet details...</p>
            </div>
          )}

          <div className={styles.option} onClick={() => toggleDropdown('bank')}>
            <div className={styles.optionIcon}>
              <img src={bankImg} alt="Bank" />
            </div>
            <div className={styles.optionName}>
              <p>Net Banking</p>
              <span>Select from a list of banks</span>
            </div>
            <div className={styles.dropDownIcon}>
              {isBankDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {isBankDropdownOpen && (
            <div className={styles.optionDetails}>
              <p>Bank details...</p>
            </div>
          )}

          <div className={styles.option} onClick={() => toggleDropdown('cash')}>
            <div className={styles.optionIcon}>
              <img src={cashImg} alt="Cash" />
            </div>
            <div className={styles.optionName}>
              <p>Cash on Delivery</p>
            </div>
            <div className={styles.dropDownIcon}>
              {isCashDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {isCashDropdownOpen && (
            <div className={styles.optionDetails}>
              <p>Cash on delivery details...</p>
            </div>
          )}
        </div>
      </div>

{/* Show Features */}
<div className={styles.showFeatures}>
  <div className={styles.feature}>
    <div className={styles.featureIcon}>
      <img src={cartBagImg} alt="CartBagImg" />
    </div>
    <div className={styles.featureText}>
      <p>100% SECURE PAYMENTS</p>
    </div>
  </div>
  <div className={styles.feature}>
    <div className={styles.featureIcon}>
      <img src={EasyReturnsImg} alt="EasyReturnsImg" />
    </div>
    <div className={styles.featureText}>
      <p>EASY RETURNS & INSTANT REFUNDS</p>
    </div>
  </div>
  <div className={styles.feature}>
    <div className={styles.featureIcon}>
      <img src={OriginalImg} alt="OriginalImg" />
    </div>
    <div className={styles.featureText}>
      <p>100% GENUINE PRODUCT</p>
    </div>
  </div>
</div>

<div className={styles.weAccept}>
    <div className={styles.weAcceptImg}>
        <img src={GooglePayImg} alt="GooglePayImg" />
    </div>
    <div className={styles.weAcceptImg}>
        <img src={upiImg} alt="upiImg" />
    </div>
    <div className={styles.weAcceptImg}>
        <img src={PhonePeImg} alt="PhonePeImg" />
    </div>
    <div className={styles.weAcceptImg}>
        <img src={visaImg} alt="visaImg" />
    </div>
    <div className={styles.weAcceptImg}>
        <img src={masterCardImg} alt="masterCardImg"/>
    </div>
</div>



    </div>
  );
};

export default PaymentPage;

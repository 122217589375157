import React from "react";
import styles from "./Home.module.css";
import Navbar from "../../components/Navbar/Navbar";
import BottomNav from "../../components/BottomNav/BottomNav";
import CategoryScroll from "../../components/CategoryScroll/CategoryScroll";
import CardScroll from "../../components/CardScroll/CardScroll";
import CardContainer from "../../components/CardContainer/CardContainer";
import TopHitsSection from "../../components/TopHits/TopHits";
import ShopByCategory from "../../components/ShopByCategory/ShopByCategory";
import top_hit_image_1 from "../../assets/top-hits-1.jpg";
import fandome_img_1 from "../../assets/fandome-img-1.jpg";
import banner from "../../assets/discount-banner.webp";
import sneaker_banner from '../../assets/sneaker-banner.webp'
import Footer from "../../components/Footer/Footer";

const Home = () => {
  const swipperImageCards = [
    {
      image:
        "https://plus.unsplash.com/premium_photo-1707932495000-5748b915e4f2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1707932495000-5748b915e4f2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1707932495000-5748b915e4f2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D",
    },
    // Add more cards as needed
  ];

  const totalCards = swipperImageCards.length;

  const trendingData = [
    {
      id: 1,
      title: "Trending Item 1",
      subtitle: "Subtitle 1 shekljfjak;ljf;kjslk;fjklasddjkljl",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      overlayText: "Hot Sale!",
      currentPrice: "$299",
      originalPrice: "$599",
      discount: "15%",
    },
    {
      id: 2,
      title: "Trending Item 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  const newArrivalsData = [
    {
      id: 1,
      title: "New Arrival 1",
      subtitle: "Subtitle 1",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "New Arrival 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  const topHitsData = [
    { image: top_hit_image_1 },
    { image: top_hit_image_1 },
    // Add more items
  ];

  const savingZoneData = [
    {
      id: 1,
      title: "Save 1",
      subtitle: "Subtitle 1",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "Save 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  const sneakersData = [
    {
      id: 1,
      title: "Sneaker 1",
      subtitle: "Subtitle 1",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "Sneaker 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  const chillProofData = [
    {
      id: 1,
      title: "Chill-proof 1",
      subtitle: "Subtitle 1",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "Chill-proof 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  const shopByFandomData = [
    { image: fandome_img_1 },
    { image: fandome_img_1 },
    // Add more items
  ];

  const pantsData = [
    {
      id: 1,
      title: "pants 1",
      subtitle: "Subtitle 1",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "pants 2",
      subtitle: "Subtitle 2",
      image:
        "https://images.pexels.com/photos/157675/fashion-men-s-individuality-black-and-white-157675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // Add more items
  ];

  return (
    <div>

      <Navbar />

      <CategoryScroll />

      <div className={styles.trending_product_scroller}>
        <CardScroll cardsData={swipperImageCards} totalCards={totalCards} />
        {/* Static Banner Below the Scrollable Cards */}
        <div className={styles.staticBanner}>
          <img src={banner} alt="discount-Banner" />
        </div>
      </div>

      <CardContainer
        heading="Trending Now"
        cardsData={trendingData}
        exploreMoreLink="/trending"
      />

      <CardContainer
        heading="New Arrivals"
        cardsData={newArrivalsData}
        exploreMoreLink="/new-arrivals"
      />

      <TopHitsSection
        heading="Top Hits"
        cardsData={topHitsData}
        exploreMoreLink="/top-hits"
      />

      <CardContainer
        heading="Saving Zone"
        cardsData={savingZoneData}
        exploreMoreLink="/saving-zone"
      />

      <CardContainer
        heading="Sneaker Zone"
        cardsData={sneakersData}
        exploreMoreLink="/sneakers"
      />

      <CardContainer
        heading="Chill-Proof collection"
        cardsData={chillProofData}
        exploreMoreLink="/chillproof"
      />

      <ShopByCategory />
      
      <div className={styles.shopByFandom}>
      <div className={styles.sneaker_banner}>
          <img src={sneaker_banner} alt="discount-Banner" />
        </div>
      <TopHitsSection heading="Shop By FANDOM" cardsData={shopByFandomData} />
      </div>

      <CardContainer
        heading="Pants"
        cardsData={pantsData}
        exploreMoreLink="/pants"
      />

      <div className={styles.originals_category_card}>
        <div className={styles.heading}>
          <h3>
            Uni Clothes <span>Originals</span>
          </h3>
        </div>

        <CardScroll cardsData={swipperImageCards} totalCards={totalCards} />

      </div>
      <CardContainer
        heading="Pants"
        cardsData={pantsData}
        exploreMoreLink="/pants"
      />

      <div className={styles.originals_category_card}>
        <div className={styles.heading}>
          <h3>
            To Hot To Be <span>Missed</span>
          </h3>
        </div>
        <CardScroll cardsData={swipperImageCards} totalCards={totalCards} />
      </div>

      <div className={styles.originals_category_card}>
        <div className={styles.heading}>
          <h3>
            Our <span>Best Picks</span>
          </h3>
        </div>
        <CardScroll cardsData={swipperImageCards} totalCards={totalCards} />
      </div>
      
      Home
      <Footer />
      <BottomNav />
    </div>
  );
};

export default Home;

import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaShoppingCart, FaChevronRight } from "react-icons/fa";
import styles from "./Navbar.module.css";
import logo from "../../assets/logoTransparent.png";
import { useNavigate } from 'react-router-dom';
import cart from '../../Pages/Cart/Cart'
import menIcon from '../../assets/men-icon.webp';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sideMenuRef = useRef(null);
  const navigate = useNavigate();

  const handleCartClick = () => {
    navigate('/cart'); // Navigates to the cart page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
      setIsMenuOpen(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLeft}>
        <button className={styles.menuIcon} onClick={toggleMenu}>
          <FaBars />
        </button>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.navbarRight}>
      <button className={styles.cartIcon} onClick={handleCartClick}>
        <FaShoppingCart />
      </button>
    </div>

      {isMenuOpen && (
        <>
          <div className={styles.sideMenu} ref={sideMenuRef}> {/* Attach ref to the side menu */}
            
            <div className={styles.menuHeading}>
              <h3>Welcome Guests</h3>
              <a href="/login" className={styles.loginSignup}>
                Login/Signup
              </a>
            </div>

            <div className={styles.listcontainer}>
              <h5 className={styles.listheading}>SHOP IN</h5>
              <div className={styles.listItem}>Men <FaChevronRight /></div>
              <div className={styles.listItem}>Accessories <FaChevronRight /></div>
              <div className={styles.listItem}>Sneakers <FaChevronRight /></div>
              <div className={styles.listItem}>Saving Zone <FaChevronRight /></div>
              <div className={styles.listItem}>Shop By Fandom <FaChevronRight /></div>
              <div className={styles.listItem}>Specials <FaChevronRight /></div>
            </div>

            <div className={styles.listcontainer}>
              <h5 className={styles.listheading}>ENGAGE</h5>
              <div className={styles.listItem}>Vote For Design <FaChevronRight /></div>
              <div className={styles.listItem}>Refer and earn </div>
              <div className={styles.listItem}>Membership </div>
              <div className={styles.listItem}>Find a Store </div>
            </div>

            <div className={styles.listcontainer}>
              <h5 className={styles.listheading}>CONTACT US</h5>
              <div className={styles.listItem}>Help and Support </div>
              <div className={styles.listItem}>Feedback Suggestions </div>
            </div>

            <div className={styles.listcontainer}>
              <h5 className={styles.listheading}>ABOUT US</h5>
              <div className={styles.listItem}>Our Story </div>
            </div>

            <div className={styles.loginbtn}>
            <a href="/login" >
                LOGIN
              </a>
            </div>
          </div>

          {/* Overlay for dimming the background */}
          <div className={styles.overlay}></div>
        </>
      )}
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import styles from './TopHits.module.css';

const TopHitsSection = ({ heading, cardsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigate for routing

  // Auto-scroll logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
    }, 3000); // Change card every 3 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [cardsData.length]);

  // Scroll to the current card
  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollLeft = currentIndex * scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }, [currentIndex]);

  // Handle dot click
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  // Handle card click to navigate to the collection page
  const handleCardClick = () => {
    navigate('/collection'); // Redirect to collection page
  };

  return (
    <div className={styles.topHitsSection}>
      <div className={styles.topHitsHeader}>
        <h2 className={styles.heading}>{heading}</h2>
      </div>
      <div className={styles.topHitsScroll} ref={scrollContainerRef}>
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={styles.topHitItem}
            onClick={handleCardClick} // Call handleCardClick when any card is clicked
          >
            <img src={card.image} alt={`Card ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Dots pagination */}
      <div className={styles.dotsContainer}>
        {cardsData.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${index === currentIndex ? styles.activeDot : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default TopHitsSection;

import React from "react";
import styles from "./Footer.module.css";
import payment_img from '../../assets/secure-payments-image.webp'

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <button className={styles.downloadButton}>DOWNLOAD THE APP</button>
      <div className={styles.text}>
        <p>100% secure payments | Free return and exchange</p>
      </div>
      <div className={styles.payment_img}>
        <img src={payment_img} alt="" />
        </div>
    </div>
  );
};

export default Footer;

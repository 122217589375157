import React from 'react'
import styles from './Profile.module.css'
import SingleProduct from '../SingleProduct/SingleProduct'
import Payment from '../CheckOut/Payment/Payment';

const Profile = () => {
  return (
    <div>
      <Payment/>
    </div>
  )
}

export default Profile

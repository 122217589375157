import React from 'react';
import styles from './Card.module.css';
import { CiHeart } from "react-icons/ci";

const Card = ({ heading, cardsData = [], exploreMoreLink, onClick }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
      </div>
      <div className={styles.cardScroll}>
        {cardsData.length > 0 ? (
          cardsData.map((card) => (
            <div 
              key={card.id} 
              className={styles.cardItem} 
              onClick={() => onClick(card)} // Pass card data to the onClick handler
            >
              <div className={styles.overlayText}>{card.overlayText}</div>
              <img src={card.image} alt={card.title} className={styles.cardImage} />
              <div className={styles.cardContent}>
                <div className={styles.contentLeft}>
                  <div className={styles.brandName}>
                    <h3>UniClothes<span className={styles.trademark}>®</span></h3>
                  </div>
                  <div className={styles.subtitle}>
                    <p>{card.subtitle}</p>
                  </div>
                </div>
                <div className={styles.like}>
                  <CiHeart />
                </div>
              </div>
              <div className={styles.price}>
                <span className={styles.currentPrice}>{card.currentPrice}</span>
                <span className={styles.originalPrice}>{card.originalPrice}</span>
                <span className={styles.discount}>{card.discount}</span>
              </div>
            </div>
          ))
        ) : (
          <p>No items available</p> // Display a message if no items
        )}
      </div>
    </div>
  );
};

export default Card;

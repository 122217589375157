import React, { useState, useEffect } from "react";
import {
  FaChevronLeft,
  FaShareAlt,
  FaShoppingCart,
  FaStar,
  FaChevronRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./SingleProduct.module.css";
import img1 from "../../assets/single-product-img-1.webp";
import simImg from "../../assets/sim-img-single-product-page.svg";
import Footer from "../../components/Footer/Footer";
import { CiHeart } from "react-icons/ci";
import sizeImage from "../../assets/size-menu.webp";

const SingleProduct = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(3600); // 1 hour in seconds
  const [isOfferActive, setIsOfferActive] = useState(true);
  const [activeSize, setActiveSize] = useState(null);
  const [showSizeGuide, setShowSizeGuide] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);

  const product = {
    title: "STYLIST T-SHIRT",
    actualPrice: 799,
    discountPercentage: 37,
    description:
      "This is a stylish T-shirt made from high-quality fabric. Available in various sizes.",
    sizes: [
      { size: "S", available: true, stock: 10 },
      { size: "M", available: true, stock: 5 },
      { size: "L", available: false, stock: 0 },
      { size: "XL", available: true, stock: 8 },
      { size: "2XL", available: true, stock: 3 },
      { size: "3XL", available: true, stock: 2 },
    ],
    images: [img1, img1, img1],
    colors: ["GREEN", "BLUE"],
  };

  const [activeColor, setActiveColor] = useState(product.colors[0]); // Default to the first color

  // Calculate the discounted price
  const discountedPrice = Math.round(
    product.actualPrice -
      (product.actualPrice * product.discountPercentage) / 100
  );

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Handle scroll event to change navbar background color
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle clicking on a size button
  const handleSizeClick = (size) => {
    if (size.available) {
      setSelectedSize(size.size);
    }
  };
  // size guide
  const handleSizeGuideClose = () => {
    setShowSizeGuide(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <img
          src={product.images[currentImageIndex]}
          alt={product.title}
          className={styles.productImage}
        />
        <div
          className={`${styles.navbar} ${isScrolled ? styles.scrolled : ""}`}
        >
          <Link to="/">
            <div className={styles.icon}>
              <FaChevronLeft />
            </div>
          </Link>
          <div className={styles.iconRight}>
            <div className={styles.icon}>
              <FaShareAlt />
            </div>
            <div className={styles.icon}>
              <FaShoppingCart />
            </div>
          </div>
        </div>

        {/* Label below the navbar */}
        <div className={styles.label}>
          <div className={styles.labelName}>
            <span>BUY 3 FOR 999</span>
          </div>
        </div>

        {/* Bottom-left corner: Rating */}
        <div className={styles.bottomLeft}>
          <FaStar className={styles.starIcon} />
          <span>4.3 | 783 </span>
        </div>

        {/* Bottom-right corner: Show More icon */}
        <div className={styles.bottomRight}>
          <img src={simImg} alt="" />
        </div>
      </div>

      {/* Pagination dots below the image */}
      <div className={styles.paginationDots}>
        {product.images.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currentImageIndex ? styles.activeDot : ""
            }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>

      {/* Product Details */}
      <div className={styles.detailsSection}>
        <div className={styles.heartIcon}>
          <CiHeart className={styles.iconHeart} />
        </div>
        <h1 className={styles.title}>{product.title}</h1>
        <p className={styles.description}>{product.description}</p>

        {/* Price section */}
        <div className={styles.priceSection}>
          <div className={styles.price}>
            <span className={styles.discountedPrice}>₹{discountedPrice}</span>
            <span className={styles.actualPrice}>₹{product.actualPrice}</span>
            <span className={styles.discountPercentage}>
              {product.discountPercentage}% OFF
            </span>
          </div>
          <div className={styles.taxes}>
            <p>inclusive of all taxes</p>
          </div>
        </div>

        <div className={styles.featureOfProduct}>
          <div className={styles.feature1}>Oversized</div>
          <div className={styles.feature2}>Trending</div>
          <div className={styles.feature3}>100% Cotton</div>
        </div>
      </div>

      {/* Offer Countdown */}
      {/* <div className={styles.offerCountDown}>
          {isOfferActive ? (
            <>
              <span>Offer ends in: {formatTime(timeRemaining)}</span>
            </>
          ) : (
            <span>Offer has ended</span>
          )}
        </div> */}

      <div className={styles.gapDiv}></div>

      <div className={styles.availableColors}>
        <div className={styles.colorHeading}>
          <h2>
            COLOUR: <span>{activeColor}</span> {/* Display the active color */}
          </h2>
        </div>
        <div className={styles.colorBox}>
          {product.colors.map((color, index) => (
            <div
              key={index}
              className={`${styles.colorName} ${
                color === activeColor ? styles.activeColor : ""
              }`}
              style={{ backgroundColor: color }}
              onClick={() => setActiveColor(color)} // Set the clicked color as active
            ></div>
          ))}
        </div>
      </div>

      <div className={styles.gapDiv}></div>

      <div className={styles.container}>
        <div className={styles.sizeSelector}>
          <div className={styles.sizeHeading}>
            <h2>Select Size: </h2>
            <p
              onClick={() => setShowSizeGuide(true)}
              className={styles.sizeGuide}
            >
              SIZE GUIDE
            </p>
          </div>
          <div className={styles.sizes}>
            {product.sizes.map((size) => (
              <div key={size.size} className={styles.sizeWrapper}>
                <div
                  className={`${styles.sizeButton} ${
                    !size.available || size.stock === 0
                      ? styles.disabledSize
                      : selectedSize === size.size
                      ? styles.activeSize
                      : ""
                  }`}
                  onClick={() =>
                    size.available && size.stock > 0 && handleSizeClick(size)
                  }
                  disabled={!size.available || size.stock === 0}
                >
                  <span>{size.size}</span>{" "}
                  {/* Display size inside the button */}
                </div>
                {size.stock < 10 && size.available && size.stock > 0 && (
                  <p className={styles.stockCount}>{size.stock} left</p>
                )}
              </div>
            ))}

            {showSizeGuide && (
              <div className={styles.overlay}>
                <div className={styles.sizeGuideContent}>
                  <button
                    className={styles.closeButton}
                    onClick={handleSizeGuideClose}
                  >
                    &times;
                  </button>
                  <h2>Size Guide</h2>
                  <div className={styles.sizeChartImage}>
                    <img src={sizeImage} alt="size menu" />
                  </div>
                  <div className={styles.inchandcm}>
                    <p className={styles.inch}>Inch</p>
                    <p className={styles.cms}>CMs</p>
                  </div>

                  <div className={styles.table}>
                    <table className={styles.sizeGuideTable}>
                      <thead>
                        <tr>
                          <th>Size</th>
                          <th>Chest (inches)</th>
                          <th>Front (inches)</th>
                          <th>Sleeve (inches)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>S</td>
                          <td>42.0</td>
                          <td>29.0</td>
                          <td>9.75</td>
                        </tr>
                        <tr>
                          <td>M</td>
                          <td>44.0</td>
                          <td>29.75</td>
                          <td>10.0</td>
                        </tr>
                        <tr>
                          <td>L</td>
                          <td>46.0</td>
                          <td>30.5</td>
                          <td>10.25</td>
                        </tr>
                        <tr>
                          <td>XL</td>
                          <td>48.0</td>
                          <td>31.25</td>
                          <td>10.5</td>
                        </tr>
                        <tr>
                          <td>XXL</td>
                          <td>50.0</td>
                          <td>32.0</td>
                          <td>10.75</td>
                        </tr>
                        <tr>
                          <td>XXXL</td>
                          <td>52.0</td>
                          <td>32.75</td>
                          <td>11.0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.gapDiv}></div>

      <div className={styles.saveExtraWithOffersBanner}>
        <span>Save Extra With Offers</span>
        <div className={styles.banner}>
          icon Get Extra 10% instant discount on all orders above Rs.2999.
          Coupon code -<strong>FLAT10</strong>
        </div>
      </div>

      <div className={styles.deliveryDetails}>
        <div className={styles.heading}>
          mapIcon
          <span>
            Delivering all across <p>India</p>
          </span>
        </div>
      </div>

      <div className={styles.keyHighlights}></div>

      <div className={styles.productReviews}></div>

      <div className={styles.moreFromTheBrand}></div>

      <div className={styles.recentlyViewed}></div>

      {/* Disable the Add to Cart button if the offer ends */}
      <button className={styles.addToCartButton} disabled={!isOfferActive}>
        {isOfferActive ? "Add to Cart" : "Offer Expired"}
      </button>

      <div className={styles.youMayAlsoLike}>
        <div className={styles.heading}>
          <h2>You May Also Like</h2>
        </div>
        <div className={styles.slidingCards}>{/* <Card/> */}</div>
        <div className={styles.staticMoreCollection}>
          <div className={styles.moreCollection}>
            <div className={styles.contentMoreCollection}>
              <img src="" alt="" />
              <p>More Men Regular Fit T-Shirt</p>
            </div>
            <div className={styles.moreCollectionIcon}>
              <FaChevronRight />
            </div>
          </div>

          <div className={styles.moreCollection}>
            <div className={styles.contentMoreCollection}>
              <img src="" alt="" />
              <p>More Men Red T-Shirt</p>
            </div>
            <div className={styles.moreCollectionIcon}>
              <FaChevronRight />
            </div>
          </div>

          <div className={styles.moreCollection}>
            <div className={styles.contentMoreCollection}>
              <img src="" alt="" />
              <p>More Men Collection</p>
            </div>
            <div className={styles.moreCollectionIcon}>
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footerSection}>
        <Footer />
      </div>

      <div className={styles.couponCode}>
        <div className={styles.couponImg}>
          <img src={simImg} alt="Coupon" /> {/* Placeholder image */}
        </div>
        <div className={styles.couponText}>
          <p>
            Get <span>{product.discountPercentage}%</span> + Extra ₹500 OFF
          </p>
        </div>
        <div className={styles.couponCodeText}>
          <p>
            USE CODE: <span>FLAT10</span>
          </p>
        </div>
      </div>

      <div className={styles.addToCartButton}>
        <button>
          <FaShoppingCart style={{ marginRight: "8px" }} />{" "}
          {/* Icon before text */}
          ADD TO BAG ₹{discountedPrice}
        </button>
      </div>
    </div>
  );
};

export default SingleProduct;
